<template>
<div class="display-block">
    <v-card>
        <v-card-text>
            <h3>Product Details :</h3>
            <row-item label="Title  " :value="product.title"></row-item>
            <row-item label="Description : " :value="product.desc"></row-item>
            <row-item label="Purchase Price : " :value="product.purchasePrice.toString()"></row-item>
            <row-item label="Price : " :value="product.price.toString()"></row-item>
            <row-item v-if="product.offerprice" label="Offer Price : " :value="product.offerprice.toString()"></row-item>
            <row-item label="Brand : " :value="product.brand ? product.brand.displayName : ''"></row-item>
            <v-layout wrap>
                <v-flex xs12 sm4 md3 class="pa-1" v-for="image in product.images" :key="image.filename" width="200">
                    <video-card :src="image.path" v-if="image.path&&image.mimetype.startsWith('video/')"></video-card>
                    <image-card :src="image.path" v-else-if="image.path&&image.mimetype.startsWith('image/')"></image-card>
                </v-flex>
            </v-layout>
            <row-item label="Category"></row-item>
                <v-chip v-for="items in product.category" class="ma-1" :key="items.category" pill>{{items.displayName}}</v-chip>
        </v-card-text>
        <v-flex text-right>
            <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
        </v-flex>
    </v-card>
</div>
</template>

<script>
import RowItem from '@/components/RowItem'
import axios from 'axios';
import appConstants from '@/utils/appConstants'
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            id: 0,
            product: {
                id: "",
                title: "",
                desc: "",
                price: 0,
                purchasePrice:0,
                offerprice: 0,
                brand: {},
                images: []
            },
            items: [],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.product = await this.getItem(appConstants.PRODUCTS_API + "/" + this.id)
        },
    }
}
</script>
